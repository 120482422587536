.banner{
    height: 90vh;
    width: 100%;
    position: relative;
}
.banner img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.content{
    position: absolute;
    top: 65%;
    left: 0px;
}

.interial-con{
    font-size: 50px !important;
    color: rgb(205 10 40);
    font-family: "Poppins", sans-serif;
    margin-left: 13px;
}
.FORM-BANNER{
    width: 700px;
    position: absolute;
    top:15%;
    left: 70%;
    right: 55px;
}
.content h3{
    color: white;
    margin-top: -359px !important;
    max-width: 38rem;
    margin-left: 55px;
    fill: #000 !important;
    font-family: "Poppins", sans-serif;
}

.toggleSwitch_wrapper__2VcEv .toggleSwitch_grouped__3VVOI {
    width: 100%;
}



.toggleSwitch_outer__13Iz- {
    -moz-box-pack: justify;
    -moz-box-align: center;
    align-items: center;
    color: #616161;
    cursor: pointer;
    display: -moz-box;
    display: flex;
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 5px;
}




@media (max-width: 1024px){
    .FORM-BANNER {
        position: absolute;
        top: 4%;
        left: 63%;
        right: 55px;
    }

    
    }
@media (max-width: 400px){
    .banner {
        height: 43vh !important;
        width: 100%;
        position: relative;
    }
    }
    
    .banner{
        background:linear-gradient(180deg, black, transparent 50%),
        url("../../Assets//inttttt.jpeg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .heloo-g{
        margin-left: -39px;
    }

    /* .banner-front {
        padding-top: 65px;
        background-image: linear-gradient(180deg, black, transparent 50%);
    } */


    @media (max-width: 424px){
        .content h3 {
            color: white;
            margin-top: -160px !important;
            max-width: 38rem;
            margin-left: 7px;
            fill: #000 !important;
            font-family: "Poppins", sans-serif;
        }

        .interial-con {
            font-size: 39px !important;
            color: rgb(205 10 40);
            font-family: "Poppins", sans-serif;
            margin-left: 13px;
        }

        }