.about-us-wrapper[data-v-27950023] .story-description {
    font-size: 16px;
    line-height: 24px;
    max-width: 918px;
}

.breadcrumb[data-v-798b613d] {
    padding: 32px;
}

.breadcrumb[data-v-798b613d] {
    max-width: 1150px;
    margin: auto;
    padding: 32px 20px;
}

.caption_default {
    font-size: 12px;
    line-height: 20px;
}

.about-heading{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 45px;
    line-height: 54px;
    color: #41303e;
    font-weight: 700;
    
}

.story-description[data-v-6173e26c] {
    max-width: 900px;
    margin: 8px auto 0;
}

.red-tape[data-v-6173e26c] {
    height: 35px;
    width: 4px;
    left: 0;
    border-radius: 2px;
    background-color: var(--primary-main);
    top: 7px;
}

.absolute {
    position: absolute;
}

.profile-wrapper[data-v-66955cb0] {
    max-width: 440px;
}

@media (min-width: 640px) {
    .sm\:mr-8 {
        margin-right: 2rem;
    }
}

.text-center {
    text-align: center;
}

.profile-wrapper[data-v-66955cb0] img {
    min-height: 200px;
}
.m-auto {
    margin: auto;
}

.right-section{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.right-section img{
    border-radius: 50%;
}

.body_default{
    max-width: 648px;
}
.cr-7{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-wrapper img{
   border-radius: 50%;
}

.founder-sec{
margin-top: 142px;
}

.founder-section{
    display: flex;justify-content: center;
}