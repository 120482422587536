.carousel[data-v-8ff92856] .swiper-slide:hover {
    box-shadow: 0 15px 20px rgba(0, 0, 0, .05), 0 0 15px 10px rgba(0, 0, 0, .1);
    transform: translateY(-2px);
}

.list-view-carousel[data-v-175fc4f6] .swiper-slide {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
    border-radius: 16px;
}

.carousel[data-v-8ff92856] .swiper-slide {
    transition: .3s ease-in-out;
}

.swiper-container.swiper-slide {
    height: auto;
}

*, :after, :before {
    box-sizing: border-box;
    border: 0 solid;
}

.items-center {
    align-items: center;
}



.image-container[data-v-8ff92856] {
    height: 177px;
}
.image-container img[data-v-8ff92856] {
    height: 32vh;
}



.h4, .h5, h4, h5 {
    line-height: 24px;
}

@media (min-width: 768px) {
    .md\:w-3\/5 {
        width: 60%;
    }
}

@media (min-width: 768px) {
    .md\:text-center {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .md\:leading-7 {
        line-height: 1.75rem;
    }
}

.text-center {
    text-align: center;
    color: black;
    list-style: none;
    text-decoration: none;
}

:where(.css-dev-only-do-not-override-zg0ahe).ant-btn-primary {
    color: #fff;
    background: red !important;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

:where(.css-dev-only-do-not-override-zg0ahe).ant-btn-default {
    background: red !important;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.88);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.padd{
    padding-top: 70px;
}

.btn-primary {
    --bs-btn-color: black !important;
    --bs-btn-bg: red !important;
    --bs-btn-border-color: red !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: red !important;
    --bs-btn-hover-border-color: #0a58ca !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: red;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}
.card-subtitle[data-v-8ff92856] {
    color: black;
}

.h4, h4 {
    font-size: 20px;
}

a{
    text-decoration: none !important;
    list-style: none !important;
}

.bg-primary {
    /* --bs-bg-opacity: 1; */
    background-color: rgba(13,110,253, var(--bs-bg-opacity)) !important;
}

.material-icons, .material-icons-outlined {
    display: flex;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    color:RED !important;
    justify-content: center ;
}

.grt-quot{
    color: rgb(187, 6, 6) !important;
}

.ser-btnn{
    background-color: red !important;
    padding-bottom: 50px;
}

.FORM-BANNE{
    left: 20% !important;
    position: absolute;
    right: 55px;
    top: 15%;
    width: 700px;
}