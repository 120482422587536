

.homepageBanner_form-container__2ZEE3 {
    display: inline-block;
    position: relative;
    width: 22rem;
    height: 300px;
}

.bfcForm_cityLandingBfc__2QBnl .bfcForm_bfcTitle1__3cH7a {
    font-size: 24px;
    margin-bottom: 0;
    text-align: left;
}

.bfcForm_errMsg__2FOFZ {
    color: #e71c24;
    display: none;
    font-size: 10px;
    font-weight: 500;
    line-height: 22px;
    margin: 6px 0 0
}

.bfcForm_dis-none__1W4rq,.bfcForm_hide__227P5 {
    display: none
}

.bfcForm_bfcBtn__2mCXH {
    background-color: #e71c24!important;
    border-color: #e71c24;
    border-radius: 8px;
    color: #fff;
    font-size: 14px!important;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 14px;
    padding-top: 14px;
    padding: 10px 13px!important;
    width: 100%
}

.bfcForm_orcond__26_VG {
    border-top: 1px solid #f0f0f0;
    margin: 18px 0 0;
    margin-bottom: 18px!important;
    padding: 0;
    position: relative
}

.bfcForm_orcond__26_VG:after {
    background: #fff;
    color: #848484;
    content: "OR";
    font-size: 11px;
    font-weight: 400;
    left: 42%;
    padding: 5px;
    position: absolute;
    top: -14px
}

#bfcForm_deskBfcFrm__Am2Gt,#bfcForm_popup-btn__3za3v {
    display: none
}

#bfcForm_popup-btn__3za3v .bfcForm_registerLoginContainer__lrgC4,.bfcForm_registerLoginContainer__lrgC4 {
    background-color: #fff;
    display: inline-block!important;
    z-index: 9
}

#bfcForm_bfc_popup__wrzM7 #bfcForm_close_popUP__G-Y1c,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic #bfcForm_close_popUP__G-Y1c {
    display: block!important
}

#bfcForm_popup-btn__3za3v .bfcForm_registerComponent__2qSic,.bfcForm_registerComponent__2qSic {
    padding: 20px
}

.bfcForm_registerComponent__2qSic .bfcForm_optwhatsappcont__2WB6- {
    font-weight: 700;
    margin-top: 10px
}

.bfcForm_registerComponent__2qSic .bfcForm_term-condition-line__qpig9 {
    margin-top: 6px
}

.bfcForm_registerComponent__2qSic .bfcForm_btn-block__q815q {
    padding-top: 10px
}

.bfcForm_desktop-offer-img__WnHaP {
    bottom: 10vh;
    left: 4.5vw;
    position: absolute
}

.bfcForm_desktop-offer-img__WnHaP img {
    max-width: 51%
}

.bfcForm_mobile-offer-img__2Ipqn {
    display: none
}

.bfcForm_errMsg__2FOFZ.bfcForm_success__2rkEB {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d
}

.bfcForm_registerLoginContainer__lrgC4 {
    display: -moz-box;
    display: flex;
    width: 100%
}

.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB .bfcForm_form-input__2UiIr,.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB .bfcForm_registerComponent__2qSic {
    background: #f2efeb;
    color: #222!important
}

.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB ::-webkit-input-placeholder {
    color: grey
}

.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB ::-moz-placeholder {
    color: grey
}

.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB :-ms-input-placeholder {
    color: grey
}

.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB ::placeholder {
    color: grey
}

.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB .bfcForm_mainBtn__1tXif {
    background: -webkit-gradient(linear,left top,right top,from(#aa8017),to(#7f5501));
    background: -moz-linear-gradient(left,#aa8017 0,#7f5501 100%);
    background: linear-gradient(90deg,#aa8017,#7f5501);
    border: unset;
    border-radius: 5px;
    letter-spacing: 1.8px
}

.bfcForm_registerLoginContainer__lrgC4.bfcForm_luxe__2dkBB .bfcForm_mainBtn__1tXif.bfcForm_loader__IA0qm {
    background: url(https://super.homelane.com/loading_dots.GIF) no-repeat 50%,-webkit-gradient(linear,left top,right top,from(#aa8017),to(#7f5501));
    background: url(https://super.homelane.com/loading_dots.GIF) no-repeat 50%,-moz-linear-gradient(left,#aa8017 0,#7f5501 100%);
    background: url(https://super.homelane.com/loading_dots.GIF) no-repeat 50%,linear-gradient(90deg,#aa8017,#7f5501)
}

.bfcForm_registerLoginContainer__lrgC4 {
    border-radius: 8px;
    display: -moz-box!important;
    display: flex!important;
    z-index: 9
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi {
    line-height: 1;
    margin-bottom: 18px;
    position: relative;
    text-align: left
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_mobileTick__Jjt5g {
    display: none;
    top: 12px
}

@media(max-width: 768px) {
    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_mobileTick__Jjt5g {
        top:18px
    }
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_intlTelInput__237bu {
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 41px;
    position: relative
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_intlTelInput__237bu.bfcForm_under-border__2cPq0 {
    border: 0!important;
    border-bottom: 1px solid #ccc!important;
    border-radius: unset
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_intlTelInput__237bu.bfcForm_under-border__2cPq0 input {
    border: 0!important
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_intlTelInput__237bu .bfcForm_intl-tel-input__2NK3g {
    width: 100%
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_intlTelInput__237bu input {
    border: 0
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi option {
    font-size: 14px
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi .bfcForm_btn-block__q815q {
    margin-bottom: 0
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi svg {
    fill: #4caf50;
    display: inline-block;
    height: 18px;
    position: absolute;
    right: 5px;
    top: 26%;
    width: 18px
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P {
    position: relative
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P svg:first-of-type {
    display: block!important;
    height: 14px;
    left: 8px;
    top: 13px;
    width: 13px
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P svg:first-of-type .bfcForm_svgColor__YsQNY {
    fill: #9b9b9b!important
}

@media(max-width: 768px) {
    .bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P svg:first-of-type {
        top:19px
    }
}

@media(min-width: 800px) and (max-width:1400px) {
    .bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P svg:first-of-type {
        top:31%
    }
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P input {
    padding-left: 30px!important;
    padding-right: 30px!important
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b {
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    background: #fff;
    border-radius: 2px;
    border-bottom-left-radius: 8px;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,.3);
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: -moz-box;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    min-height: auto;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 9999
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b::-webkit-scrollbar-track {
    background-color: #f4f3ee;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3)
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b::-webkit-scrollbar {
    background-color: #f4f3ee;
    width: 6px
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b::-webkit-scrollbar-thumb {
    background-color: #bababa;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3)
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2 {
    border-top: 1px solid #e6e6e6;
    color: #515151;
    cursor: default;
    display: -moz-box;
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    line-height: 30px;
    padding: 3px 6px;
    text-align: left;
    white-space: nowrap
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2 .bfcForm_pac_icon__21ZpG {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-position: -1px -161px;
    background-size: 34px;
    display: inline-block;
    flex-basis: 20px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    vertical-align: top;
    width: 15px
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2 .bfcForm_pac_icon__21ZpG.bfcForm_marker__3kUXG {
    background-position: -18px -161px
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2 .bfcForm_pac_item_query__35Vwt {
    color: #000;
    flex-basis: 90%;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 3px 0 0;
    white-space: normal
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2 .bfcForm_pac_item_query__35Vwt .bfcForm_pac_matched__34Bei {
    font-weight: 700
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2:hover {
    background-color: #fafafa
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2:hover .bfcForm_pac_icon__21ZpG {
    background-position: -18px -161px
}

.bfcForm_registerLoginContainer__lrgC4 #bfcForm_property__35b9P .bfcForm_placesContainer__1vX-b .bfcForm_pac_item__3yFd2 :nth-child(3) {
    flex-basis: 100%;
    font-size: 13px;
    line-height: 20px;
    padding-bottom: 10px;
    padding-left: 26px;
    white-space: normal
}

.bfcForm_loginComponent__2cMKb,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic {
    background: #fff;
    border-radius: 8px;
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%
}

.bfcForm_registerComponent__2qSic.bfcForm_exitintent__1E5Fs {
    padding: 36px 20px 18px
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_sideImage__E4pp- {
    height: 100%;
    width: 45%
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_optWhatsappLabel__17jor {
    -webkit-touch-callout: none;
    color: #616161;
    cursor: pointer;
    display: inline;
    font-size: 11px;
    font-weight: 700;
    margin-left: 0;
    margin-right: 5px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.bfcForm_registerLoginContainer__lrgC4 label.bfcForm_error-lab__26t8c {
    color: #e71c24;
    font-size: 10px;
    font-weight: 500;
    margin: 0
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_mainBtn__1tXif {
    background-color: #e71c24!important;
    border: 1px solid;
    border-radius: 2px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.12px;
    line-height: 22px;
    margin-bottom: 0;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_orOtherSignOptionsMain__3NhJ7 {
    margin-top: 10px;
    padding: 0 24px
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_orOtherSignOptions__3tTHa {
    text-align: center
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_orSignUpTxtSpan__39pp8 {
    color: #212529;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_exist-user__2L27x {
    color: #212529;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px
}

.bfcForm_frm-input__1D-3b:focus,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b:active {
    outline: none
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_socialLinks__8sXzb #bfcForm_facebook_Color__3_jPZ {
    margin-right: 10px
}

.bfcForm_registerLoginContainer__lrgC4 .bfcForm_social-links__img__2msLm {
    cursor: pointer
}

.bfcForm_form-input__2UiIr,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #474a50!important;
    display: block;
    font-size: 13px;
    font-weight: 500;
    height: 48px!important;
    line-height: 1.42857143;
    padding: 6px 12px;
    width: 100%
}

.bfcForm_form-input__2UiIr.bfcForm_inner-input-child__26Tjd,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b.bfcForm_inner-input-child__26Tjd {
    border: 0;
    margin-left: 47px!important;
    padding-left: 0!important;
    padding-right: 0!important;
    width: -moz-calc(100% - 47px)!important;
    width: calc(100% - 47px)!important;
    z-index: -1!important
}

.bfcForm_form-input__2UiIr.bfcForm_add-country-box__38XVl,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b.bfcForm_add-country-box__38XVl {
    border: 0;
    left: 46px;
    position: absolute;
    top: 0;
    width: -moz-calc(100% - 47px)!important;
    width: calc(100% - 47px)!important
}

.bfcForm_errLink__2gYHC {
    color: #e71c24;
    font-weight: 600;
    text-decoration: underline
}

.bfcForm_optWhatsappLabel__17jor,.bfcForm_optwhatsappcheckbox__VWJff {
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    margin: 0 3px 0 0;
    vertical-align: sub
}

.bfcForm_optwhatsappcont__2WB6- {
    line-height: 0;
    margin-top: 5px
}

.bfcForm_form-label__1msJ8 {
    color: #474a50!important;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -.34px;
    line-height: 22px;
    margin-bottom: 0!important;
    max-width: 100%
}

#bfcForm_close_popUP__G-Y1c {
    color: #e71c24;
    display: block;
    font-size: .8em;
    margin: auto;
    padding-top: 10px;
    text-align: center;
    text-decoration: underline
}

.bfcForm_closeBfc__fdX5q {
    border-radius: 50%;
    color: #8e9194;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    height: 17px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 10px;
    width: 18px
}

.bfcForm_closeBfc__fdX5q.bfcForm_closepopup__mXEsz {
    top: 0
}

.bfcForm_login__3oRdi {
    background: #fff;
    color: #e71c24;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 14px
}

.bfcForm_term-condition-line__qpig9 {
    color: #000;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center
}

.bfcForm_term-condition-line__qpig9.bfcForm_luxe__2dkBB a {
    color: #783d06
}

.bfcForm_term-condition-link__3u0dN {
    color: #e71c24
}

#bfcForm_forgotPassword__122GY {
    color: #f95656;
    float: right;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    text-decoration: none
}

.bfcForm_loginComponent__2cMKb h4,.bfcForm_registerComponent__2qSic h4 {
    font-size: 17px
}

#bfcForm_registerLoginContainerForm__1wKvk #bfcForm_proceed-anyway__35b7C {
    padding: 10px!important
}

.bfcForm_closeProceedAnyway__JVlr8 {
    color: #8e9194;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    padding: 0 10px;
    text-align: right
}

.bfcForm_BfcFormWrapper__168cm .bfcForm_closeProceedAnyway__JVlr8 {
    margin: -17px -20px
}

.bfcForm_closeProceedAnyway__JVlr8:hover {
    color: #000
}

.bfcForm_proceedAnywayWrapper__3xDgu {
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-align: center;
    align-items: center;
    color: #000;
    display: -moz-box;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin: 5% 0;
    text-align: center
}

.bfcForm_proceedAnywayWrapper__3xDgu * {
    padding: 15px
}

.bfcForm_proceedAnywayWrapper__3xDgu h2,.bfcForm_proceedAnywayWrapper__3xDgu p {
    margin: 0
}

#bfcForm_desk-form__342w_ .bfcForm_registerComponent__2qSic .bfcForm_proceedAnywayWrapper__3xDgu p,.bfcForm_proceedAnywayWrapper__3xDgu h2 {
    padding: 0
}

#bfcForm_proceed-anyway-btn__1mIOP {
    background: #e71c24;
    border-style: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    outline: none;
    padding: 10px 20px
}

.bfcForm_loader__IA0qm {
    background: url(https://super.homelane.com/loading_dots.GIF) no-repeat 50%;
    color: transparent!important;
    text-indent: 0
}

.bfcForm_loader__IA0qm .bfcForm_label__NW7wY,.bfcForm_loader__IA0qm .bfcForm_largeText__2hTo1 {
    opacity: 0
}

.bfcForm_bfcContainer__3JofO .bfcForm_bfcTitle2__2Adbt {
    margin: 15px;
    text-align: center
}

@media only screen and (max-device-width: 450px) and (max-device-height:892px) {
    .bfcForm_reglog__2iV1c {
        border:none;
        padding: 3px 0
    }

    .bfcForm_optWhatsappLabel__17jor {
        color: #616161;
        cursor: pointer;
        display: inline;
        font-size: 10px;
        font-weight: 500;
        margin-right: 5px;
        position: relative;
        top: -3.5px
    }

    .bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_sideImage__E4pp- {
        width: 60%
    }

    .bfcForm_bfcOuterBox__2Tvbd .bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic,.bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_loginComponent__2cMKb,.bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic {
        -webkit-box-shadow: 0 0 3px #f0f0f0;
        box-shadow: 0 0 3px #f0f0f0;
        padding: 20px;
        width: 100%
    }

    .bfcForm_sideImage__E4pp- {
        display: none
    }

    .bfcForm_bfcContainer__3JofO h4 {
        color: #333;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
        margin: 15px 15px 15px 0;
        text-align: left!important
    }

    .bfcForm_bfcContainer__3JofO .bfcForm_bfcTitle1__3cH7a {
        margin: 15px;
        text-align: center!important
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_social-links__img__2msLm {
        cursor: pointer
    }

    .bfcForm_registerLoginContainer__lrgC4 svg#bfcForm_google-sign-up__tXHos {
        height: 44px;
        width: 51px
    }

    .bfcForm_loginComponent__2cMKb h4,.bfcForm_registerComponent__2qSic h4 {
        font-size: 20px;
        text-align: center
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b:active,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b:focus {
        outline: none
    }

    .bfcForm_main-header__block__1_kr3,.bfcForm_main-header__left-wrapper__2d8HH,.bfcForm_main-header__right-wrapper__2x2OI {
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        display: -moz-box;
        display: flex;
        flex-direction: column
    }

    .bfcForm_admin-info__Y98Sk.bfcForm_admin-info--sm__1CAZA a>img {
        height: 30px;
        width: 30px
    }

    #bfcForm_navigation__1UC4u {
        width: 100%
    }

    li#bfcForm_litoggle__39Bg0 {
        margin-bottom: 15px
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_errorInput__1xW7C {
        border-color: #e71c24!important
    }
}

@media only screen and (min-device-width: 1280px) and (min-device-height:700px) {
    .bfcForm_outerBox__2FX6a {
        margin:2% auto;
        width: 60%
    }

    .bfcForm_main-header__block__1_kr3 {
        -moz-box-pack: justify;
        display: -moz-box;
        display: flex;
        justify-content: space-between
    }

    #bfcForm_close_popUP__G-Y1c {
        color: #e71c24;
        display: block;
        font-size: .7em;
        margin: auto;
        padding-top: 5px;
        text-align: center;
        text-decoration: underline
    }

    .bfcForm_bfcContainer__3JofO .bfcForm_form-input__2UiIr {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        color: #474a50;
        display: block;
        font-weight: 500;
        height: auto!important;
        line-height: 1.42857143;
        padding: 10px 12px!important;
        width: 100%
    }

    .bfcForm_bfcContainer__3JofO .bfcForm_googleInputField__2bqZs {
        border: 1px solid #ccc
    }

    .bfcForm_bfcContainer__3JofO .bfcForm_location__G-ltx svg {
        margin-left: 5px
    }

    .bfcForm_errMsg__2FOFZ {
        color: #e71c24;
        display: none;
        font-size: 10px;
        font-weight: 500;
        line-height: 22px;
        margin: 6px 0 0
    }

    .bfcForm_errMsg__2FOFZ a:hover {
        color: #007bff
    }

    .bfcForm_errMsg__2FOFZ.bfcForm_success__2rkEB {
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #3c763d
    }

    .bfcForm_form-label__1msJ8 {
        font-size: 12px;
        line-height: 20px
    }

    .bfcForm_thankPopup__33vNq {
        -moz-box-pack: space-evenly;
        color: #737373;
        font-size: .9em;
        height: 100%;
        justify-content: space-evenly;
        line-height: 1.5;
        margin: auto;
        padding: 8%;
        text-align: center
    }

    .bfcForm_thankDiv__2Nlia,.bfcForm_thankPopup__33vNq {
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-align: center;
        align-items: center;
        display: -moz-box;
        display: flex;
        flex-direction: column
    }

    .bfcForm_thankDiv__2Nlia {
        -moz-box-pack: justify;
        height: 50%;
        justify-content: space-between
    }

    .bfcForm_bfcOk__1I0i0 {
        background-color: #e71c24;
        border-style: none;
        color: #e9e2e1;
        font-weight: 800;
        margin: 2% 0;
        padding: 3%;
        width: 50%
    }

    #bfcForm_plaDesk__1j3p0 {
        display: block
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_orOtherSignOptions__3tTHa {
        margin: 0
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b {
        font-size: 12px;
        line-height: 11px;
        padding: 0 0 6px!important
    }

    .bfcForm_sideImage__E4pp->img {
        height: 100%;
        width: 100%
    }

    .bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_sideImage__E4pp- {
        width: 55%
    }

    .bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_loginComponent__2cMKb,.bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic {
        padding: 2% 2% 0 3%;
        width: 50%
    }

    .bfcForm_optWhatsappLabel__17jor {
        color: #616161
    }

    .bfcForm_loginComponent__2cMKb h4,.bfcForm_registerComponent__2qSic h4 {
        color: #474a50;
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        margin: 0;
        padding: 0 0 14px;
        text-align: center
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_exist-user__2L27x {
        font-size: 12px
    }

    .bfcForm_close-link__3TOQR {
        display: none
    }

    .bfcForm_outerBoxToggle__2ZdW3 {
        width: 55%!important
    }

    .bfcForm_sideImageToggle__239L2 {
        width: 50%!important
    }

    #bfcForm_sideLogToggle__1LqWW {
        padding: 5% 2% 0 3%;
        width: 50%!important
    }
}

@media only screen and (min-device-width: 1280px) and (min-device-height:800px) {
    .bfcForm_button-container__3COwK {
        height:100px;
        margin: 10px auto
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_sideImage__E4pp- {
        display: inline
    }

    .bfcForm_BfcFormContainer__2VZ7Z #bfcForm_proceed-anyway__35b7C {
        margin: 0
    }

    #bfcForm_proceed-anyway-btn__1mIOP {
        margin: 15px 0
    }

    #bfcForm_registerLoginContainerForm__1wKvk #bfcForm_proceed-anyway__35b7C {
        border: 1px solid #eee;
        margin: 50px 30px;
        padding: 20px!important
    }
}

@media only screen and (min-device-width: 1366px) and (min-device-height:768px) {
    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi {
        margin-bottom:18px!important
    }
}

@media only screen and (min-device-width: 1440px) and (min-device-height:900px) {
    .bfcForm_button-container__3COwK {
        width:60%
    }

    .bfcForm_outerBox__2FX6a {
        margin: 3% auto;
        width: 60%
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi {
        margin-bottom: 20px!important
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi.bfcForm_exitintent__1E5Fs {
        margin-bottom: 12px!important
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi.bfcForm_exitintent__1E5Fs.bfcForm_btn-block__q815q {
        margin-bottom: 0!important
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b {
        font-size: 13px
    }

    .bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_sideImage__E4pp- {
        width: 50%
    }

    .bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_loginComponent__2cMKb,.bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic {
        padding: 2%;
        width: 50%
    }
}

@media only screen and (min-device-width: 1600px) and (min-device-height:900px) {
    .bfcForm_outerBoxToggle__2ZdW3 {
        width:50%!important
    }
}

@media only screen and (min-device-width: 1920px) and (min-device-height:1080px) {
    .bfcForm_outerBox__2FX6a,.bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_sideImage__E4pp- {
        width:52%
    }

    .bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_loginComponent__2cMKb,.bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic {
        padding: 2% 2% 2% 4%;
        width: 50%
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-input__1D-3b {
        font-size: 13px
    }

    .bfcForm_registerLoginContainer__lrgC4 .bfcForm_frm-grp__1ZfJi {
        margin-bottom: 20px!important
    }
}

.bfcForm_referrelCodeHeaderSec__L3nwU .bfcForm_referrelTittleDiv__2VvAg {
    border-bottom: 1px solid #e5e5e5;
    margin: 0 -20px;
    padding: 0 20px 20px
}

.bfcForm_referrelCodeHeaderSec__L3nwU .bfcForm_referrelTittleDiv__2VvAg .bfcForm_ref_homeLogo__1GibS {
    width: 100px
}

.bfcForm_referrelCodeHeaderSec__L3nwU .bfcForm_referrelTittleDiv__2VvAg .bfcForm_welcome_text__1cs_X {
    color: #9a9a9a;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    margin: 14px 0 5px;
    padding: 0;
    text-align: left
}

.bfcForm_referrelCodeHeaderSec__L3nwU .bfcForm_referrelTittleDiv__2VvAg .bfcForm_referrel_content__3cXjL {
    color: #9a9a9a;
    font-size: 14px;
    margin: 0
}

.bfcForm_referrelCodeHeaderSec__L3nwU .bfcForm_referrelCode_text__3U2Wx {
    color: #9a9a9a;
    font-size: 12px;
    margin: 10px 0
}

.bfcForm_referrelCodeHeaderSec__L3nwU .bfcForm_referrelCode_text__3U2Wx .bfcForm_highlited_code__2NuD9 {
    background-color: #ffe1e1
}

.bfcForm_propertyLevel_section__23w9Y {
    margin-bottom: 15px;
    margin-top: -15px
}

.bfcForm_propertyLevel_section__23w9Y .bfcForm_clock_svg__2yrvw {
    margin-right: 5px
}

.bfcForm_propertyLevel_section__23w9Y .bfcForm_propertyLevel_text__1MEVu {
    color: #e71c24;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px
}

.bfcForm_frm-grp__1ZfJi .bfcForm_googleInputField__2bqZs {
    border: 1px solid #ccc;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    padding: 10px 0 10px 30px;
    width: 100%
}

.bfcForm_location__G-ltx svg {
    left: 0;
    margin-left: 5px;
    position: absolute;
    z-index: 8
}

.bfcForm_location__G-ltx .bfcForm_svgColor__YsQNY {
    fill: #9b9b9b
}

.bfcForm_activeProperty__2If9H .bfcForm_svgColor__YsQNY {
    fill: #e71c24
}

.bfcForm_frm-grp__1ZfJi .bfcForm_star__2-HiF {
    color: #e71c24;
    position: absolute;
    right: 25px;
    top: 16px;
    z-index: 9
}

.bfcForm_cityLandingBfc__2QBnl .bfcForm_frm-grp__1ZfJi .bfcForm_form-input__2UiIr {
    border: none!important;
    border-bottom: 1px solid #cacaca!important;
    border-radius: 0;
    font-size: 14px;
    padding-left: 0!important
}

.bfcForm_cityLandingBfc__2QBnl .bfcForm_frm-grp__1ZfJi .bfcForm_googleInputField__2bqZs {
    border: none!important;
    border-bottom: 1px solid #cacaca!important
}

.bfcForm_cityLandingBfc__2QBnl .bfcForm_location__G-ltx svg {
    margin-left: 0!important
}

.bfcForm_cityLandingBfc__2QBnl #bfcForm_property__35b9P svg:first-of-type {
    left: 0
}

.bfcForm_cityLandingBfc__2QBnl #bfcForm_property__35b9P .bfcForm_form-input__2UiIr {
    padding-left: 20px!important
}

.bfcForm_cityLandingBfc__2QBnl .bfcForm_bfcTitle1__3cH7a {
    font-size: 24px;
    margin-bottom: 0;
    text-align: left
}

@media(max-width: 786px) {
    .bfcForm_bfcBtn__2mCXH {
        font-size:12px!important
    }

    .bfcForm_outerBox__2FX6a .bfcForm_registerLoginContainer__lrgC4 {
        background: #f0f0f0;
        border-radius: 8px;
        height: 500px;
        overflow: scroll
    }

    .bfcForm_optWhatsappLabel__17jor,.bfcForm_optwhatsappcheckbox__VWJff {
        margin-right: 3px;
        top: -3px;
        vertical-align: text-bottom
    }

    .bfcForm_loginComponent__2cMKb,.bfcForm_registerLoginContainer__lrgC4 .bfcForm_registerComponent__2qSic {
        position: relative
    }

    #bfcForm_popup-btn__3za3v .bfcForm_registerLoginContainer__lrgC4,.bfcForm_registerLoginContainer__lrgC4 {
        border-radius: 8px;
        margin: 3% auto;
        width: 90%!important
    }

    #bfcForm_popup-btn__3za3v .bfcForm_registerLoginContainer__lrgC4.bfcForm_removeWidth__2A6eo,.bfcForm_registerLoginContainer__lrgC4.bfcForm_removeWidth__2A6eo {
        width: 100%!important
    }

    .bfcForm_cityLandingBfc__2QBnl .bfcForm_frm-grp__1ZfJi .bfcForm_form-input__2UiIr {
        padding-bottom: 0!important
    }

    .bfcForm_cityLandingBfc__2QBnl .bfcForm_bfcTitle1__3cH7a {
        margin-bottom: 20px
    }

    .bfcForm_cityLandingBfc__2QBnl .bfcForm_bfcBtn__2mCXH {
        font-size: 14px!important
    }
}

@media(min-width: 700px) and (max-width:1366px) {
    .bfcForm_frm-grp__1ZfJi {
        margin-bottom:25px!important
    }

    .bfcForm_registerComponent__2qSic {
        padding-top: 40px
    }

    .bfcForm_site-wise__2jGzR {
        padding-top: 20px
    }
}

.bfcForm_bfc__IRpmv {
    -moz-box-align: center;
    -moz-box-pack: center;
    align-items: center;
    background-color: #e71c24;
    border: 1px solid #e71c24;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: -moz-box;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    letter-spacing: -.12px;
    line-height: 22px;
    margin: 0 auto;
    padding: 10px 13px;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
    z-index: 1
}

.bfcForm_bfc__IRpmv span {
    pointer-events: none
}

.bfcForm_bfc__IRpmv .bfcForm_largeText__2hTo1 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin: auto 5.2px 0 3.2px;
    text-shadow: 1px 1px #8e0b10,2px 2px #8e0b10;
    -webkit-transform: translateX(0) translateY(-2px);
    -moz-transform: translateX(0) translateY(-2px);
    transform: translateX(0) translateY(-2px)
}

.bfcForm_bfc__IRpmv .bfcForm_label__NW7wY {
    background: #ffc700;
    border-radius: 2px;
    color: #1e1e1e;
    font-size: 10px;
    font-weight: 700;
    height: 20px;
    line-height: 10px;
    margin: auto 0 auto 13px;
    overflow: hidden;
    padding: 5px 9px;
    position: relative;
    width: 43px
}

.bfcForm_bfc__IRpmv .bfcForm_label__NW7wY:before {
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-duration: .8s;
    -moz-animation-duration: .8s;
    animation-duration: .8s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bfcForm_sheen__qyatG;
    -moz-animation-name: bfcForm_sheen__qyatG;
    animation-name: bfcForm_sheen__qyatG;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;
    background-color: #ffda03;
    content: "";
    display: block;
    height: 85%;
    position: absolute;
    top: 0;
    -webkit-transform: skewY(-45deg);
    -moz-transform: skewY(-45deg);
    transform: skewY(-45deg);
    width: 40px;
    z-index: 1
}

.bfcForm_bfc__IRpmv .bfcForm_label__NW7wY:after {
    content: "FREE";
    position: relative;
    z-index: 2
}

@-webkit-keyframes bfcForm_sheen__qyatG {
    0% {
        left: -50%;
        -webkit-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }

    50% {
        -webkit-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }

    to {
        left: 100%;
        -webkit-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }
}

@-moz-keyframes bfcForm_sheen__qyatG {
    0% {
        left: -50%;
        -moz-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }

    50% {
        -moz-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }

    to {
        left: 100%;
        -moz-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }
}

@keyframes bfcForm_sheen__qyatG {
    0% {
        left: -50%;
        -webkit-transform: skewY(-45deg);
        -moz-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }

    50% {
        -webkit-transform: skewY(-45deg);
        -moz-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }

    to {
        left: 100%;
        -webkit-transform: skewY(-45deg);
        -moz-transform: skewY(-45deg);
        transform: skewY(-45deg)
    }
}

.bfcForm_bfcBtnWrapper__20PaP button {
    width: 100%
}

.bfcForm_bfcTitle1-luxe__tsYIE {
    font-weight: 300!important
}

.bfcForm_stylish__d7lc6 {
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 48px;
    margin-left: 4px
}

.OverlayLoginPopup_overlay-login-popup__14EcT {
    -moz-box-align: end;
    -moz-box-pack: center;
    align-items: flex-end;
    -webkit-backdrop-filter: blur(.1px);
    backdrop-filter: blur(.1px);
    background-color: transparent;
    display: -moz-box;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-wrapper-bg__1kwIc {
    background-color: #dcdcdc;
    height: 100%;
    opacity: .9;
    position: relative;
    width: 100%
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z {
    background-color: #fff;
    border: 1px solid #ccc;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    bottom: 0;
    height: auto;
    left: 0;
    margin: 0 auto;
    padding: 20px 20px 0;
    position: absolute;
    right: 0;
    width: 90%;
    z-index: 9999
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_existing-heading__3Lv6J {
    color: #474a50;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    padding: 15px 0 0;
    text-align: left
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_existing-heading__3Lv6J .OverlayLoginPopup_highlightText__10X_E {
    color: #e71c24;
    font-size: 14px!important
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_box-title__2d1bs {
    color: #474a50;
    font-size: 24px;
    font-weight: 800;
    line-height: 28.8px;
    padding: 16px 0 0;
    text-align: left
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 {
    -webkit-box-shadow: 0 0 3px #f0f0f0;
    box-shadow: 0 0 3px #f0f0f0;
    display: inline-block;
    height: 100%;
    margin: 13px 0 0;
    width: 100%
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_close__24Xr- {
    cursor: pointer;
    height: 12px;
    position: absolute;
    right: 16px;
    top: 6px;
    width: 12px
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_close__24Xr- svg {
    height: 12px;
    width: 12px
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e {
    line-height: 1;
    padding-bottom: 26px;
    position: relative
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_phoneBorder__F2596 {
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    position: relative
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_frm-input__32CjD {
    -webkit-appearance: none;
    background: 0 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    line-height: 11px;
    padding: 0 40px 10px 0;
    position: relative;
    z-index: 2
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_frm-input__32CjD.OverlayLoginPopup_inner-input-child__EJdmt {
    margin-left: 47px!important;
    opacity: 0;
    padding-left: 0!important;
    padding-right: 0!important;
    visibility: hidden;
    width: -moz-calc(100% - 47px);
    width: calc(100% - 47px)
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_frm-input__32CjD.OverlayLoginPopup_boxborderHeight__FDwUL {
    border: 0!important;
    height: 48px!important
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_frm-input__32CjD.OverlayLoginPopup_add-country-box__1JvuD {
    left: 46px;
    position: absolute;
    top: 0;
    width: -moz-calc(100% - 47px);
    width: calc(100% - 47px)
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_frm-input__32CjD.OverlayLoginPopup_boxborderInput__3_Pin {
    border: 0!important;
    height: 48px!important;
    padding: 6px 12px!important
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_errMsg__2SCRL {
    bottom: 6px;
    color: #e71c24;
    font-size: 10px;
    font-weight: 500;
    left: 0;
    margin: 0;
    position: absolute
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_frm-grp__1GE1e .OverlayLoginPopup_errMsg__2SCRL .OverlayLoginPopup_errLink__-flw9 {
    color: #e71c24;
    font-weight: 600;
    text-decoration: underline
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_mainBtn__14wK2 {
    background-color: #e71c24;
    background-image: none;
    border: 1px solid;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.12px;
    line-height: 22px;
    margin-bottom: 0;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_orOtherSignOptionsMain__3Yes3 {
    padding: 13px 24px 0
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_orOtherSignOptions__1xJI6 {
    margin: 0;
    text-align: center
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_orSignUpTxtSpan__2PGL8 {
    color: #212529;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_socialLinks__NjeDe {
    -moz-box-pack: center;
    display: -moz-box;
    display: flex;
    height: 35px;
    justify-content: center;
    margin-top: 10px
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_socialLinks__NjeDe svg {
    cursor: pointer;
    height: 35px;
    vertical-align: middle;
    width: 35px
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_exist-user__B5Xe- {
    color: #e71c24;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 19px;
    padding-top: 34px;
    text-align: center
}

.OverlayLoginPopup_overlay-login-popup__14EcT .OverlayLoginPopup_overlay-container__rY6_Z .OverlayLoginPopup_loginComponent__3bWJ7 .OverlayLoginPopup_exist-user__B5Xe- .OverlayLoginPopup_login__1fYlM {
    color: #e71c24;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    text-decoration: underline
}

.OverlayLoginPopup_error-lab__14IFe {
    bottom: -19px;
    color: #e71c24;
    font-size: 10px;
    font-weight: 500;
    left: 0;
    line-height: 22px;
    margin: 6px 0 0;
    position: absolute
}

.OverlayLoginPopup_otpWrapper__2nAdf {
    margin: -20px -20px 0
}

.about-mar{
    margin-top: 100px !important;
}


/* ***************************************************************************************** */


                                          /* new add page */

.wpb_row{
    margin-bottom: 50px;
}

.titlee{
    display: flex;justify-content: center; align-items: center;
}

.divider{
    display: flex;justify-content: center;
}

/*  */
.svg-triangle-icon {
    fill: transparent;
    stroke:#ff0000;
    stroke-width: 3;
}

.divider {
    width: 100%;
    max-width: 370px;
    height: 1px;
    margin: 40px auto;
    display: block;
    position: relative;
    background: #e5e5e5;
}

.svg-triangle-icon-container {
    width: 22px;
    height: 15px;
    overflow: visible !important;
    left: 50%;
    margin-left: -11px;
    margin-top: -6px;
    display: block;
    position: absolute;
}

.pmg-gapp{
    padding: 0 70px;
}