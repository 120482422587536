*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}