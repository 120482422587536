.p-3 {
    padding: 0.75rem;
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
    
}
.flexx {
    display: flex;
}

.gJg {
    background-color: #fff;
    border: 1px solid #eceaeb;
    border-radius: 8px;
    height: 500px;
}



.body_smal, .subtitle_smal {
    font-size: 14px;
    line-height: 20px;
}

.box-shado {
    box-shadow: 0 2px 4px 0 rgba(65, 48, 62, .15);
}

.h-ful{
    height: 500px;
}

.django{
    justify-content: center;
}

.subtitle_larg {
    font-weight: 500;
}
.body_lar, .subtitle_larg {
    font-size: 20px;
    line-height: 28px;
    width: max-content;
}

.bgc-color{
    /* margin-top: 50px; */
    background: lightgray;
}

.what-we{
    display: flex;
    margin-top: 45px;
    justify-content: center;
}

.whatt-we{
    display: flex;
    justify-content: center;
    margin-top: 45px !important;
}


.gj-bro{
    display: flex;
    justify-content: center;
    margin-top: 45px !important;

}

.gj-bro, .what-we, .whatt-we {
    display: flex;
    margin-top: 30px !important;
    justify-content: center;
}



@media (max-width: 700px){
    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x)* .5);
        padding-left: 45px !important;
        margin-bottom: 25px !important;
        margin-top: var(--bs-gutter-y);
    }
   }
@media (max-width: 768px){
    .h-ful {
        height: 500px;
        width: 342px !important;
        margin-top: 25px;
    }
   }