.img-gallery img{
    width: 540px !important;
    padding: 10px;
    border-radius: 15px !important;
   
}

.view-gall{
    display: flex;
    justify-content: end;
    color: black !important;
}
.view-gall a{
    color: black !important;
    font-size: medium;
    border-radius: 13px;
    padding: 7px;
    background: red;
    border: 2px solid red;
}
.view-gall a:hover{
    color:white !important;
   
}

