.text-p{
  color: white;
}

.span-color{
  color: white;
}

.counter-h3-css{
  color: white;
 font-size:  3.75rem;

}

.span-color{
  color: red;
}