.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  height: 15vh;
  background-color: white;
  border-bottom: 1px solid rgb(222, 222, 222);
  backdrop-filter: blur(8px);
  /* Adjust blur radius as needed */
  -webkit-backdrop-filter: blur(8px);
  /* background: url("../../Images/backgroun\ theam\ nav.jpeg"); */
  background-size: cover;
  /* For Safari */
}

.navbar-brand{
  padding: 5px 15px;
}

.Logo-Con {
  height: 10vh;
  width: auto;
  z-index: 100;
}

.Logo-Con img {
  height: 100%;
  width: 100%;
}

/* ///////////// */

.bg-body-tertiary {
  padding: 0px !important;
}

.navbar-expand-lg .navbar-collapse {
  /* display: flex !important;
  flex-basis: auto;
  justify-content: space-between !important;
  align-items: center; */
}

.navbar-expand-lg .navbar-nav-scroll {
  overflow: visible;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
  margin: 0px 10px;
}

/* ///////////////////// */

.Login-Btn {}

.Login-Btn button {
  border: none;
  padding: 6px 30px;
  font-size: 14px;
  border-radius: 50px;
  color: white;
  background: var(---Color02);
}


.navbar-expand-lg .navbar-nav .nav-link a {
  text-decoration: none;
  color: black !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.NavDropdown title{
  text-decoration: none;
  color: black !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.navbar-expand-lg .navbar-nav .nav-link a:hover {
  color:red !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: var(---color01) !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.dropdown-menu a {
  color: var(---color01) !important;
}

.dropdown-menu a a {
  text-decoration: none;
  color: black !important;
}

.social-media-links-con {
  /* border: 1px solid blue; */
}

.social-media-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-media-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0px 6px;
  background-color: var(---color01);
  border-radius: 50px;
  transition: all .5s ease-in-out;
  border: 1px solid var(---color01);
}

.social-media-links a svg {
  font-size: 20px;
  color: white;
  transition: all .5s ease-in-out;
}

.social-media-links a:hover {
  background-color: white;
  border: 1px solid var(---color01);
}

.social-media-links a:hover svg {
  color: red;
}

.btn-enq {
  background-color: initial; /* Set the initial background color of the button */
  color: initial;           /* Set the initial text color of the button */
  border: initial;          /* Set the initial border of the button */
  padding: 10px 20px;       /* Set padding for the button */
  font-size: 16px;          /* Set font size for the button */
  width: max-content !important;
  cursor: pointer;          /* Set cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
}

.btn-enq:hover {
  background-color: red;    /* Change background color to red on hover */
  color: white;             /* Change text color to white on hover (optional) */
}



@media(max-width:992px) {

  .navbar-expand-lg .navbar-nav-scroll {
      overflow: auto;
      width: auto;
      display: unset;
      justify-content: center;
      text-align: right;
  }

  .Login {
      display: none !important;
  }

  .navbar-toggler {
      background-color: white !important;
  }
}

@media(max-width:430px) {
  .Logo-Con {
      height: 7vh;
      max-width: 18vh;
  }
}

.btn-dark {
  --bs-btn-color: red !important;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

/* nav.css */
.Navbarrr{
 position: sticky !important;
 top: 0 !important;
}


