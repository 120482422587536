.bg-black {
    margin-top: 109px;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    padding-top: 17px;
    margin-top: 19px;
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
    margin-top: 59px;}

    .titl{
        color: red;
        list-style: none;
        text-decoration: none;
    }

    .footer-color{
        background:lavender;
    }

    .footer-about p{
        color: black !important;
    }

    .desc{
        color: white !important;
    }

    .social-icon-style{
        display: flex;
        justify-content: center;
    }

    .footer-title{
        display: flex;
        justify-content: center;
        color: black !important;
    }
    .footer-title ul li{
        color: white !important;
    }
   