/* General styling */
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.service-banner {
    background-color: #f5f5f5;
    padding: 50px 0;
}

.design_hero_title_revamp__1GtUQ {
    padding-top: 198px;
    /* text-align: center; */
    font-size: 2rem;
    color: white;
}


.modular-kit {
    color: #ff5722;
}

/* Paragraph styling */
.para-mod {
    margin: 20px 0;
}

.paag {
    color: #666;
}

/* Image section styling */
.images-inter {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.col-lg-3 {
    flex: 1 1 25%;
    max-width: 25%;
}

.col-md-12 {
    flex: 1 1 100%;
    max-width: 100%;
}

.col-sm-12 {
    flex: 1 1 100%;
    max-width: 100%;
}

.inter-img {
    text-align: center;
}

.inter-img img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.dis-6 {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
}

/* Media Queries */
@media (max-width: 1200px) {
    .col-lg-3 {
        flex: 1 1 50%;
        max-width: 50%;
    }
}

@media (max-width: 768px) {
    .col-lg-3 {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .design_hero_title_revamp__1GtUQ {
        font-size: 1.5rem;
    }
}
/* 
.service-banner{
    background-image: url("https://super.homelane.com/HL_tiles/banners/desktop/modular_kitchen_designs.jpg");
    height: 50vh;
} */



.hedd{
    padding: 288px;
}

.inter-imggg{
    max-height: 300px;
    object-fit: cover;
}


