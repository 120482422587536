.whyChooseUs_why-choose-us-container__MK7ng .whyChooseUs_headingTitle__34rH2 {
    color: #222;
    font-size: 34px;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 16px;
    text-align: center;
}

.why-dude{
    margin-top: 55px;
}

.hero{
    margin-top: 18px;
}


@media (max-width: 786px){
    .whycho{
        margin-top: 460px !important;
    }

    .FORM-BANNER {
        position: absolute;
        top: 98%;
        left: 2%;
        right: 55px;
    }

    }

    .markuee-ul{
        display: flex;
        list-style: none;
    }


    .transport img{
       width: 130px;
        height: 120px;
        margin-top: 20px;
       
       fill: white;
    }

    .markuee-ul{
           margin-left: 30px;
    }
    
   .us-p{
    display: flex !important;
    justify-content: center!important;
    padding-bottom: 51px;
   }