/* /////////////////////// section 1 ////////////////////////// */

.AboutUs-Section-1 {
    /* border: 1px solid blue; */
    padding: 10vh;
}

.AboutUs-Section-1-con {
    /* border: 1px solid red; */
}

.About-Section-1-Image-con {
    /* border: 1px solid gold; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.About-Section-1-Image {
    /* border: 1px solid black; */
    height: 60vh !important;
    /* width: 60vh; */
}

.About-Section-1-Image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.About-Section-1-Details-con {
    text-align: left;
}

.About-Section-1-Details-con h4 {
    font-size: 20px;
    margin-bottom: 22px;
    font-family: "Poppins", sans-serif;
    color: #777777;
}

.About-Section-1-Details {
    display: flex;
    align-items: center;
    height: 100%;
}

.About-Section-1-Details-con p {
    font-size: 15px;
}

/* /////////////////////// section 2 ////////////////////////// */

.AboutUs-Section-2 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}

.AboutUs-Section-Counter {
    /* border: 1px solid blue; */
}

.AboutUs-Section-Counter-card {
    display: flex;
    padding: 0px 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
}

.AboutUs-Section-Counter-Image-con {
    /* border: 1px solid firebrick; */
}

.AboutUs-Section-Counter-Image {
    /* border: 1px solid black; */
    height: 40px;
    width: 40px;
}

.AboutUs-Section-Counter-Image img {
    height: 100%;
    width: 100%;
}

.AboutUs-Section-Counter-card-details {
    /* border: 1px solid blue; */
    text-align: left;
    margin: 0px 0px 0px 20px;
}

.AboutUs-Section-Counter-card-details h2 {
    font-weight: 500;
    font-size: 35px;
    font-family: "Poppins", sans-serif;
    line-height: 1.2;
    transition: color .3s 0s ease-out;
}

.AboutUs-Section-Counter-card-details h3 {
    font-family: "Poppins", sans-serif;
    /* letter-spacing: 2px; */
    margin: 0px 0px 5px 0px;
    /* color: #821F40; */
    font-weight: 400;
    font-size: 18px;
    position: relative;
    z-index: 1;
    display: inline-block;
}


@media(max-width:768px) {
    .About-Section-1-Details-con {
        margin-top: 25px;
        text-align: left;
    }

    .AboutUs-Section-2 {
        /* border: 1px solid red; */
        padding: 0vh 0px 5vh 0px;
    }

}

@media(max-width:576px) {
    .AboutUs-Section-Counter-card {
        justify-content: space-around !important;
        align-items: center;
    }

    .AboutUs-Section-Counter-card-details {
        width: 60%;
    }

    .AboutUs-Section-1 {
        /* border: 1px solid blue; */
        padding: 10vh 0px;
    }

    .About-Section-1-Image-con {
        /* border: 1px solid gold; */
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .AboutUs-Section-Counter-Image {
        /* border: 1px solid black; */
        height: 40px;
        width: 40px;
    }

    .AboutUs-Section-Counter-card-details h2 {
        margin: 0px;
    }

    .About-Section-1-Image {
        /* border: 1px solid black; */
        height: auto;
        width: 100%;
        object-fit: cover;
    }
}

.learn-more {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
  }
  
  .expanded-content {
    margin-top: 10px;
  }
  